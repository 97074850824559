const ActivityHelper = {
  _eventEl : document.querySelector('[data-event]'),
};

ActivityHelper.addActivity = async function (activityId) {
  ActivityHelper.updateActivityQrCode(activityId);

  const activity = await ApiHelper.getActivity(activityId);
  const activityUserPhotos = await ApiHelper.getActivityUserPhotos(activityId);

  ActivityHelper.addActivityHtml(activity, activityUserPhotos);
  ActivityHelper.initSlideshow();

  return activity;
};

ActivityHelper.addActivityHtml = function (activity, photosFromUsers) {
  const html = `
    <h2>${activity.fields['Nome'] ? activity.fields['Nome'] : ''}</h2>
    <h4>${activity.start_end_hours ? activity.start_end_hours : ''}</h4>
    <div class="event-local-details">
      <h4><i class="fas fa-map-marker-alt"></i></h4>
      <div>
        <h4>${activity.fields['Local.Nome'] ? activity.fields['Local.Nome'] : ''}</h4>
        <p>
          <a href="${activity.fields['Local.LinkGoogleMaps'] ? activity.fields['Local.LinkGoogleMaps'] : ''}" target="_blank">Abrir no Google Maps <i class="fas fa-external-link-square-alt"></i></a>
        </p>
      </div>
    </div>
    ${activity.description ? activity.description : ''}
    ${activity?.fields['Fotos'] || photosFromUsers.length > 0 ? ActivityHelper.getSlideshowHtml(activity.fields['Fotos'], photosFromUsers) : ''}
    <form class="activity-photo-upload">
      <label class="nei-button activity-photo-upload__button" for="activity-photo-upload__input">adicionar foto</label>
      <input id="activity-photo-upload__input" type="file" accept="image/png, image/jpeg" hidden>
    </form>
  `;

  ActivityHelper._eventEl.innerHTML = html;

  document.querySelector('#activity-photo-upload__input').addEventListener('change', e => { ActivityHelper._handlePhotoUpload(activity.id); } );
};


ActivityHelper.getSlideshowHtml = function (photos, photosFromUsers) {
  return `<div id="nei-slideshow" class="nei-slideshow">
            <div data-glide-slideshow class="glide">
              <div data-glide-el="track" class="glide__track">
                <ul class="glide__slides">
                  ${ActivityHelper.getSlidesHtml(photos, photosFromUsers)}
                </ul>
              </div>

              <div class="nei-slideshow__controls glide__arrows" data-glide-el="controls">
                <button class="glide__arrow glide__arrow--prev" data-glide-dir="<">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 18.8 30.3" style="enable-background:new 0 0 18.8 30.3;" xml:space="preserve">
                    <polygon fill="#2A2827" points="16.3,30.3 18.8,27.5 5.4,15.1 18.8,2.7 16.3,0 0,15.1"/>
                  </svg>
                </button>
                <button class="glide__arrow glide__arrow--next" data-glide-dir=">">
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 18.8 30.3" style="enable-background:new 0 0 18.8 30.3;" xml:space="preserve">
                    <polygon fill="#2A2827" points="2.5,0 0,2.8 13.4,15.2 0,27.6 2.5,30.3 18.8,15.2"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>`;
};

ActivityHelper.getSlidesHtml = function (photos, photosFromUsers) {
  var slidesHtml = '';

  if(photos && photos.length > 0)
    photos.forEach(photo => {
      var html =
        `<li class="glide__slide">
            <a data-pswp-width="${photo.width}" data-pswp-height="${photo.height}"
               data-pswp-src="${photo.thumbnails.full.url}">
              <img data-srcset="${photo.thumbnails.large.url} ${photo.thumbnails.large.width}w, ${photo.thumbnails.full.url} ${photo.thumbnails.full.width}w"
                   class="lazyload"
                   data-sizes="auto"
                   width="${photo.width}" height="${photo.height}"
                   src="${photo.thumbnails.large.url}">
            </a>
          </li>`;

      slidesHtml += html;
    });

  if(photosFromUsers.length > 0)
    photosFromUsers.forEach(photo => {
      var html =
        `<li class="glide__slide">
          <a data-pswp-width="${photo.fields.Largura}" data-pswp-height="${photo.fields.Altura}"
              data-pswp-src="${photo.fields.URL}">
            <img src="${photo.fields.URL}">
          </a>
        </li>`;

      slidesHtml += html;
    });

  return slidesHtml;
};


ActivityHelper.initSlideshow = function (activities) {
  const pswpElement = document.querySelectorAll('.pswp')[0];

  let options = {
    index: 0,
    mainClass : 'pswp--minimal--dark',
    barsSize : {top:10,bottom:10},
    captionEl : true,
    fullscreenEl : false,
    shareEl : false,
    bgOpacity : 0.65,
    tapToClose : true,
    tapToToggleControls : false
  };

  //* SLIDESHOW
  const neiSlideshows = document.querySelectorAll('.nei-slideshow');

  if(neiSlideshows.length > 0) {
    neiSlideshows.forEach(el => {
      new GlideSlideshow(el);

      el.querySelectorAll('[data-pswp-src]').forEach((img, index) => {

        img.addEventListener('click', function(e) {
          e.preventDefault();

          //if is dragging, don't open photoswipe
          if(img.getAttribute('draggable') == 'false')
            return;

          const pswpItems = [];

          options.index = index - 1;

          el.querySelectorAll('.glide__slide:not(.glide__slide--clone) [data-pswp-src]').forEach((img) => {
            const item = {
              src : img.getAttribute('data-pswp-src'),
              w : img.getAttribute('data-pswp-width'),
              h : img.getAttribute('data-pswp-height')
            };

            pswpItems.push(item);
          });

          const gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, pswpItems, options);
          gallery.init();
        });
      });
    });
  }
};

ActivityHelper.updateActivityQrCode = activityId => {
  document.getElementById('activity-qrcode-image').src = ApiHelper.getActivityQrCodeUrl(activityId);
  document.getElementById('activity-qrcode-download').href = ApiHelper.getActivityQrCodeUrl(activityId);
};


ActivityHelper._handlePhotoUpload = activityId => {
  const formData = new FormData();
  const fileField = document.querySelector('#activity-photo-upload__input');

  notie.alert({
    type: 'info',
    text: 'A enviar foto...',
    position: 'top'
  });

  if (fileField.files[0]) {
    const img = new Image();
    img.onload = function() {
      formData.append('photo', fileField.files[0]);
      formData.append('activity', activityId);
      formData.append('width', this.width);
      formData.append('height', this.height);

      fetch(ENV.API_BASE_PATH + '/photos', {
        method: 'POST',
        body: formData
      })
        .then((result) => {
          notie.alert({
            type: 'success',
            text: 'Foto enviada! 👍',
            position: 'top'
          });

          console.log('Photo upload success:', result);
        })
        .catch((error) => {
          notie.alert({
            type: 'error',
            text: 'Algo correu mal. 👎 Tentamos de novo?',
            position: 'top'
          });

          console.error('Photo upload error:', error);
        });
    };
    img.src = URL.createObjectURL(fileField.files[0]);
  }
};
